<template>
  <section>
    <div class="video-media-list">
      <div
        class="video-media-list__col"
        :class="{'video-media-list__col-tiny': !showMeta }"
        v-for="(video, index) in videos" :key="`item-${index}`"
      >
        <div class="media-item">
          <router-link
            :to="{path: `/${getVideoRoute}/${video.id}`}"
            class="media-item__image"
            :title="getVideoTitle(video)"
            :style="`background-image: url(${getVideoPreviewUrl(video)})`"
          >
            <VideoSettingInfoBadge :video="video" />
          </router-link>
          <div class="meta-info" v-if="showMeta">
            <small
              v-for="(metaInfo, index) in getMetaInfo(video)"
              :key="`video-${video.id}-metainfo-${index}`"
              class="crop-long-text"
            >
              <span v-if="metaInfo.label !== null" class="meta-info__label"> {{ metaInfo.label }}: </span>
              <span class="meta-info__value" :title="metaInfo.component ? '' : metaInfo.value">
                  <component v-if="metaInfo.component" :is="metaInfo.component" :video="metaInfo.value" />
                  <span v-else> {{ metaInfo.value }} </span>
              </span>
            </small>
          </div>
          <div
            class="item-buttons"
            :class="{'show-meta-on-hover': showMeta === false}"
          >
            <router-link
              class="item-buttons__button item-buttons__button--edit"
              :to="{path: `/${getVideoRoute}/${video.id}/edit`}"
            >
              <IconEdit class="item-buttons__button__icon" />
              {{ videoInfoText }}
            </router-link>
            <BtnCopyToClipboard
              class="btn btn-default btn-sm"
              data-test="video_list_btn_copy"
              :data="video.id | toString"
              :notify-message="$t('notify.id_was_copied')"
            />
            <router-link
              class="item-buttons__button item-buttons__button--detail"
              :to="{path: `/${getVideoRoute}/${video.id}`}"
            >
              <IconInfo class="item-buttons__button__icon" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import CheckIcon from '@/assets/img/svg/check.svg?inline'
import IconInfo from '@/assets/img/svg/info.svg?inline'
import IconEdit from '@/assets/img/svg/edit.svg?inline'
import VideoTypeIcon from '@/components/video/VideoTypeIcon'
import VideoStatus from '@/components/video/VideoStatus'
import VideoSettingInfoBadge from '@/components/video/VideoSettingInfoBadge'
import Media from '@/components/shared/Media'
import YoutubeThumbnail from '@/components/shared/YoutubeThumbnail'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'
import UserService from '@/services/user/UserService'
import MediaMixin from '@/components/mixins/Media'

export default {
  name: 'VideoListTable',
  mixins: [MediaMixin],
  props: {
    videos: {
      type: Array,
      required: true
    },
    showMeta: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    getVideoRoute () {
      if (this.$route && this.$route.name.includes('old')) {
        return 'videoOld'
      } else {
        return 'video'
      }
    },
    videoInfoText () {
      return this.$t('video.editVideo')
    }
  },
  components: {
    IconEdit,
    IconInfo,
    CheckIcon,
    VideoTypeIcon,
    VideoStatus,
    VideoSettingInfoBadge,
    Media,
    YoutubeThumbnail,
    BtnCopyToClipboard
  },
  methods: {
    searchVideos () {
      this.$store.dispatch('video/fetch')
    },
    getVideoPreviewUrl (video) {
      if (video.youtubeId) {
        return 'https://i.ytimg.com/vi/' + video.youtubeId + '/default.jpg'
      }
      const filename = video?.expanded?.previewImage?.damMediaEmbed?.filename
      return filename ? this.getDamImagePath(filename, 160, 90) : ''
    },
    getVideoTitle (video) {
      return video.youtubeId ? video?.meta?.title : video?.expanded?.previewImage?.imageCaption
    },
    prettyDate (dateTime) {
      return this.$options.filters.prettyDateTime(dateTime)
    },
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    getCreatedAtAndBy (video) {
      return `${this.prettyDate(video.createdAt)}, ${this.userNameById(video.createdBy)}`
    },
    getMetaInfo (video) {
      const result = [
        { label: this.$t('video.list.id'), value: video.id },
        { label: this.$t('video.list.type'), value: video, component: 'VideoTypeIcon' },
        { label: this.$t('video.list.title'), value: video.meta.title },
        { label: this.$t('video.list.default_site'), value: video.expanded.defaultSite.title },
        { label: this.$t('video.list.status'), value: video, component: 'VideoStatus' }
      ]
      if (video.statusMessage) {
        result.push({ label: '', value: video.statusMessage })
      }
      result.push(...[
        { label: this.$t('video.list.used_in_article'), value: video.articles.length },
        { label: this.$t('video.list.video_duration'), value: this.$options.filters.formatMilliseconds(video.videoDuration) },
        { label: this.$t('video.list.created_at'), value: this.getCreatedAtAndBy(video) }
      ])
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.meta-info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
  small {
    flex: 0 0 100%;
  }
  &__label {
    @include font(400 12px "Roboto");
    color: #979797;
  }
  &__value {
    @include font(800 12px "Roboto");
    color: #465674;
  }
}

.crop-long-text {
  white-space: nowrap;
  overflow: hidden;
  float: left;
  text-overflow: ellipsis;
}

.show-meta-on-hover {
  width: 100%;
  display: none;
  position: absolute;
  text-align: center;
  z-index: 10;
  bottom: 20px;
  left: 0;
}

.media-item {
  background-color: #FFFFFF;
  border: rem(1px) solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
  margin-bottom: 0.7rem;
  position: relative;
  &:hover {
    .show-meta-on-hover {
      display: block;
    }
  }
  &__image {
    width: 100%;
    height: rem(220px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    cursor: pointer;
    position: relative;
    margin-bottom: rem(5px);
  }
  &__label {
    float: right;
    padding: rem(3px) rem(10px);
    margin: rem(5px) rem(3px);
    @include font(700 13px "Roboto");
    color: #FFFFFF;
    line-height: rem(30px);
    border-radius: rem(6px);
    &--restricted-site {
      background-color: #fc4b6c;
    }
    &--user {
      background-color: #6599FE;
    }
  }
}
.video-media-list > div {
  padding-left: rem(5px);
  padding-right: rem(5px);
}

.video-media-list {
  display: flex;
  flex-wrap: wrap;
  &__col {
    @include bp(0 10) {
      flex: 0 0 50%;
      max-width: 50%;
      .crop-long-text {
        // prevents long text from stretching out the whole col
        max-width: calc(100vw / 2);
      }
    }
    @include bp(10 12) {
      flex: 0 0 33.3%;
      max-width: 33.3%;
      .crop-long-text {
        // prevents long text from stretching out the whole col
        max-width: calc(100vw / 3);
      }
    }
    @include bp(12) {
      flex: 0 0 25%;
      max-width: 25%;
      .crop-long-text {
        // prevents long text from stretching out the whole col
        max-width: calc(100vw / 4);
      }
    }
  }
  &__col-tiny {
    @include bp(12) {
      flex: 0 0 16.6%;
      max-width: 16.6%;
    }
  }
}

.item-buttons {
  &:not(.show-meta-on-hover) {
    display: flex;
    gap: rem(4px);

    .item-buttons__button--edit {
      width: 100%;
    }
  }
  &__button {
    @include font(700 13px "Roboto");
    border-radius: rem(6px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    background-color: #FFFFFF;
    color: #465674;
    height: rem(30px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: rem(5px);
    &:hover {
      background-color: lighten(#D1DBE4, 5%);
    }
    &__icon {
      @include size(12px);
      fill: #9DAAC4;
    }
    &--detail {
      width: 35px;
    }
  }
}
</style>
